import * as React from "react";
import { Terms, Layout } from "../components";
import Seo from "../components/Seo";

const TermsPage = () => {
  return (
    <Layout>
      <Seo
        title="特定商取引法に基づく表示"
        description="当社が定める特定商取引法に基づく表示をご確認いただけます。"
      />
      <Terms />
    </Layout>
  );
};

export default TermsPage;
